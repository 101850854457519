<template>
  <div>
    <!-- 左侧导航栏 -->
    <menu-wrapper active-index="">
      <div class="title">支付错误</div>
    </menu-wrapper>
  </div>
</template>

<script>
import menuWrapper from "@/components/user/common/menu-wrapper.vue";

export default {
  components: {
    menuWrapper,
  },
  data() {
    return {
      loading: true,
    };
  },
  created() {
    this.pageLoad();
  },
  methods: {
    pageLoad() {
      this.loading = true;
    }
  },
};
</script>
<style>
.cardRate {
  color: red;
  font-size: 16px;
  font-weight: 600;
}
.cardline {
  margin-top: 5px;
  padding-left: 80px;
}
.title {
  font-size: 30px;
  color: #757575;
  line-height: 68px;
}
</style>